import React from "react";
import "./style/App.css";

import LandingPage from "./screens/LandingPage.jsx";

function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;
